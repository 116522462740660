import React from "react";

import { ErrorFeedBack } from "../ErrorFeedBack";
import { ExpandableText } from "../ExpandableText";

import * as Styled from "./styles";
import { formatToRealCurrency } from "utils/formatToRealCurrency";
import { dateUtil } from "utils";

export const AdjustmentsTable = ({
  isError,
  isEmpty,
  financialAdjustments,
}) => {
  const tableHeaderTitles = [
    "Id",
    "Data do ajuste",
    "Período financeiro",
    "Estabelecimento",
    "Valor",
    "Motivo",
    "Responsável",
  ];

  function formatFinancialDate(date) {
    return new Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    })
      .format(new Date(date))
      .replace(", ", " às ");
  }

  return (
    <Styled.Table>
      {isError || isEmpty ? (
        <ErrorFeedBack troubleType={isError ? "errorList" : "emptyList"} />
      ) : (
        <>
          <thead>
            <tr>
              {tableHeaderTitles.map((item, index) => (
                <th key={`${index}${item}`}>
                  <span>{item}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {financialAdjustments?.map((item) => {
              return (
                <>
                  <tr>
                    <Styled.TableCell>{item.id}</Styled.TableCell>

                    <Styled.TableCell>
                      {formatFinancialDate(item.created_at)}
                    </Styled.TableCell>

                    <Styled.TableCell maxWidth={12}>
                      {dateUtil.getLongMonthAndYear(
                        new Date(item.financial_period.starts_at)
                      )}
                    </Styled.TableCell>

                    <Styled.TableCell>{item.merchant_name}</Styled.TableCell>

                    <Styled.TableCell
                      adjustmentValueCell
                      positiveValue={item.value > 0}
                    >
                      {formatToRealCurrency(item.value / 100)}
                    </Styled.TableCell>

                    <Styled.TableCell maxWidth={15}>
                      <ExpandableText
                        text={item.description}
                        truncateLength={30}
                      />
                    </Styled.TableCell>

                    <Styled.TableCell>{item.created_by_name}</Styled.TableCell>
                  </tr>
                </>
              );
            })}
          </tbody>
        </>
      )}
    </Styled.Table>
  );
};
