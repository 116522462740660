import React, { memo, useMemo, useState } from "react";

import { Controller, useFormState, useWatch } from "react-hook-form";
import ReactTooltip from "react-tooltip";

import { stringUtil } from "utils";

import { AsyncSelect } from "./AsyncSelect";
import { SearchLocationModal } from "./SearchLocationModal";
import { Input, Select } from "components";

import {
  useFetchCities,
  useZipCode,
  useMerchantManager,
  useSearchLocationOnMap,
  useFetchCuisines,
  useFetchCategories,
  useFetchAgents,
  usePaymentMethods,
} from "../hooks";
import {
  calculationWayOptions,
  getDefaultAgent,
  maritalStatusOptions,
  openingWayOptions,
  paymentStatusOptions,
  statusOptions,
} from "../initialStates";

const RESTAURANT_STORE_TYPE_ID = "1";

const DataTabComponent = ({ merchantId }) => {
  const { register, setValue, control,getValues } = useMerchantManager();

  const { errors } = useFormState({ control });
  const [
    zipCode,
    addressStreet,
    addressNumber,
    addressArea,
    city,
    cuisines,
    categories,
    contractId,
  ] = useWatch({
    control,
    name: [
      "endereco_cep",
      "endereco_rua",
      "endereco_numero",
      "endereco_bairro",
      "id_cidade",
      "storeTypes",
      "groups",
      "contract_uuid",
    ],
  });

  const { fetchAddressByZipeCode, isLoading: isLoadingZipCode } = useZipCode();
  const { fetchCities, formatCity } = useFetchCities();
  const { fetchCuisines } = useFetchCuisines();
  const { fetchCategories } = useFetchCategories();
  const { fetchAgents } = useFetchAgents();
  const {
    searchLocationOnMap,
    isLoading: isLoadingLocation,
    isModalOpened,
    addressLabel,
    setIsModalOpened,
    location,
    setLocation,
    updateLocation,
  } = useSearchLocationOnMap({
    addressStreet,
    addressNumber,
    addressArea,
    city,
  });
  const { paymentMethods, formatPaymentMethodNameByKey, togglePaymentMethod } =
    usePaymentMethods(merchantId);

  const isNotAllowedToSearchLocation =
    !zipCode || !addressStreet || !addressNumber || !addressArea || !city;
  const blockedFieldMessage = contractId
    ? "Não é possível editar enquanto há um contrato gerado."
    : "";

  const [search, setSearch] = useState("");

  const filteredPaymentMethodsList = useMemo(
    () =>
      search.length > 0
        ? paymentMethods.filter((method) =>
            method.nome.toLowerCase().includes(search.toLowerCase())
          )
        : [],
    [paymentMethods, search]
  );

  const handleZipCode = async () => {
    const response = await fetchAddressByZipeCode(zipCode);

    if (!response) {
      return;
    }

    const fieldsToPopulate = [
      { name: "endereco_rua", value: response.logradouro },
      { name: "endereco_bairro", value: response.bairro },
      { name: "id_cidade", value: formatCity(response.cidades) },
    ];

    fieldsToPopulate.forEach(({ name, value }) => setValue(name, value));
  };

  const renderPaymentMethod = (paymentMethod) => {
    const formattedPaymentMethodName = formatPaymentMethodNameByKey(
      paymentMethod.chave
    );
    if (localStorage.getItem("@ADM-Aiboo/nivel") !== "A") {
      const defaultAgent = getDefaultAgent();
      const representante = getValues("id_representante")?.value;
      if (typeof representante === "undefined") {
        setValue("id_representante", defaultAgent);
      }
    }
    return (
      <div className="col-sm-6 col-md-4" key={paymentMethod.id}>
        <div className="checkbox-inline">
          <label className="checkbox checkbox-square checkbox-primary">
            <input
              type="checkbox"
              defaultChecked={paymentMethod.selected}
              onClick={() => togglePaymentMethod(paymentMethod)}
            />
            <img
              src={`assets/media/forma-pagamento/${formattedPaymentMethodName}.png`}
              onError={(event) =>
                (event.target.src =
                  "assets/media/forma-pagamento/flag-fallback.svg")
              }
              alt="Bandeira do cartão"
              className="ml-3 mr-2 img-fluid"
              style={{ width: 50, height: 25 }}
            />
            {paymentMethod.nome}
            <span />
          </label>
        </div>
      </div>
    );
  };

  return (
    <div
      className="tab-pane fade show active"
      id="dados"
      role="tabpanel"
      aria-labelledby="dados"
    >
      <form autoComplete="off">
        <div className="row mb-6">
          <div className="col-md-12">
            <h3>Informações da empresa</h3>
            <hr />
          </div>
        </div>

        <div className="row">
          <div data-tip={blockedFieldMessage} className="form-group col-md-3">
            <Input
              label="CNPJ/CPF"
              isMandatory
              type="text"
              error={errors.cnpj_cpf}
              readOnly={!!contractId}
              {...register("cnpj_cpf", {
                required: "O campo é obrigatório.",
                onChange: (event) => {
                  const { value } = event.target;
                  event.target.value = stringUtil.maskToCpfOrCnpj(value);
                },
              })}
            />
          </div>
        </div>

        <div className="row">
          <div data-tip={blockedFieldMessage} className="form-group col-md-6">
            <Input
              label="Razão Social"
              isMandatory
              type="text"
              error={errors.razao_social}
              readOnly={!!contractId}
              {...register("razao_social", {
                required: "O campo é obrigatório.",
              })}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <Input
              label="Nome do estabelecimento"
              isMandatory
              type="text"
              error={errors.nome}
              {...register("nome", {
                required: "O campo é obrigatório.",
              })}
            />
          </div>
        </div>

        <div className="row mb-6 mt-6">
          <div className="col-md-12">
            <h3>Informações do responsável</h3>
            <hr />
          </div>
        </div>

        <div className="row">
          <div data-tip={blockedFieldMessage} className="form-group col-md-4">
            <Input
              label="Nome do contato"
              isMandatory
              type="text"
              maxLength={250}
              readOnly={!!contractId}
              error={errors.contato}
              {...register("contato", {
                required: "O campo é obrigatório.",
              })}
            />
          </div>
        </div>

        <div className="row">
          <div data-tip={blockedFieldMessage} className="form-group col-md-4">
            <Input
              label="E-mail"
              isMandatory
              type="text"
              readOnly={!!contractId}
              error={errors.email}
              {...register("email", {
                required: "O campo é obrigatório.",
              })}
            />
          </div>
        </div>

        <div className="row">
          <div data-tip={blockedFieldMessage} className="form-group col-md-3">
            <Input
              label="RG"
              type="text"
              readOnly={!!contractId}
              error={errors.rg}
              {...register("rg", {
                onChange: (event) => {
                  const { value } = event.target;
                  event.target.value = stringUtil.maskToRg(value);
                },
              })}
            />
          </div>

          <div data-tip={blockedFieldMessage} className="form-group col-md-2">
            <Input
              label="Órgão emissor"
              type="text"
              readOnly={!!contractId}
              error={errors.orgao_expedidor}
              {...register("orgao_expedidor")}
            />
          </div>
        </div>

        <div className="row">
          <div data-tip={blockedFieldMessage} className="form-group col-md-3">
            <Input
              label="CPF"
              type="text"
              readOnly={!!contractId}
              error={errors.cpf_contato}
              {...register("cpf_contato", {
                onChange: (event) => {
                  const { value } = event.target;
                  event.target.value = stringUtil.maskToCpf(value);
                },
              })}
            />
          </div>
        </div>

        <div className="row">
          <div data-tip={blockedFieldMessage} className="form-group col-md-3">
            <Controller
              name="estado_civil"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Estado civil"
                  error={errors.estado_civil}
                  placeholder={() => null}
                  options={maritalStatusOptions}
                  isDisabled={!!contractId}
                />
              )}
            />
          </div>
        </div>

        <div className="row mb-6 mt-6">
          <div className="col-md-12">
            <h3>Endereço do estabelecimento</h3>
            <hr />
          </div>
        </div>

        <div className="row d-flex align-items-center">
          <div data-tip={blockedFieldMessage} className="form-group col-md-3">
            <Input
              label="CEP"
              isMandatory
              type="text"
              readOnly={!!contractId}
              maxLength={8}
              error={errors.endereco_cep}
              {...register("endereco_cep", {
                required: "O campo é obrigatório.",
              })}
            />
          </div>

          <div>
            <button
              className={`btn ${
                isLoadingZipCode ? "bg-secondary" : "btn-primary"
              }`}
              style={{
                height: 44,
                width: 100,
                zIndex: 0,
              }}
              disabled={isLoadingZipCode || !zipCode || !!contractId}
              type="button"
              onClick={handleZipCode}
            >
              {isLoadingZipCode ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Pesquisar"
              )}
            </button>
          </div>
        </div>

        <div className="row">
          <div data-tip={blockedFieldMessage} className="form-group col-md-3">
            <Input
              label="Logradouro (Rua, avenida)"
              isMandatory
              readOnly={!!contractId}
              type="text"
              error={errors.endereco_rua}
              {...register("endereco_rua", {
                required: "O campo é obrigatório.",
              })}
            />
          </div>

          <div data-tip={blockedFieldMessage} className="form-group col-md-1">
            <Input
              label="Número"
              isMandatory
              readOnly={!!contractId}
              type="text"
              error={errors.endereco_numero}
              {...register("endereco_numero", {
                required: "O campo é obrigatório.",
              })}
            />
          </div>
        </div>

        <div className="row">
          <div data-tip={blockedFieldMessage} className="form-group col-md-3">
            <Input
              label="Bairro"
              isMandatory
              readOnly={!!contractId}
              type="text"
              error={errors.endereco_bairro}
              {...register("endereco_bairro", {
                required: "O campo é obrigatório.",
              })}
            />
          </div>

          <div data-tip={blockedFieldMessage} className="form-group col-md-3">
            <Controller
              name="id_cidade"
              control={control}
              rules={{ required: "O campo é obrigatório." }}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  label="Cidade"
                  isMandatory
                  error={errors.id_cidade}
                  loadOptions={fetchCities}
                  loadingMessage={() => "Carregando..."}
                  noOptionsMessage={() => "Cidade não encontrada"}
                  placeholder={() => null}
                  isDisabled={!!contractId}
                />
              )}
            />
          </div>
        </div>

        <div className="row d-flex align-items-center">
          <div className="form-group col-md-2">
            <Input
              label="Latitude"
              type="text"
              error={errors.lat}
              {...register("lat")}
            />
          </div>

          <div className="form-group col-md-2">
            <Input
              label="Longitude"
              type="text"
              error={errors.lng}
              {...register("lng")}
            />
          </div>

          <div>
            <button
              className={`btn ${
                isLoadingLocation ? "bg-secondary" : "btn-primary"
              }`}
              style={{
                width: 160,
                height: 44,
              }}
              type="button"
              onClick={searchLocationOnMap}
              disabled={isNotAllowedToSearchLocation || isLoadingLocation}
            >
              {isLoadingLocation ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Procurar localização"
              )}
            </button>
          </div>
        </div>

        <div className="row mb-6 mt-6">
          <div className="col-md-12">
            <h3>Configurações gerais</h3>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-2">
            <Controller
              name="status"
              control={control}
              rules={{ required: "O campo é obrigatório." }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Status"
                  isMandatory
                  error={errors.status}
                  placeholder={() => null}
                  options={statusOptions}
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <Controller
              name="storeTypes"
              control={control}
              rules={{ required: "O campo é obrigatório." }}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  label="Tipo da loja"
                  isMandatory
                  isMulti
                  loadOptions={fetchCuisines}
                  loadingMessage={() => "Carregando..."}
                  noOptionsMessage={() => "Tipo não encontrado"}
                  placeholder={() => null}
                  error={errors.storeTypes}
                  isOptionDisabled={(option) =>
                    option.isDisabled || cuisines?.length >= 3
                  }
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <Controller
              name="groups"
              control={control}
              rules={{
                required: {
                  value: cuisines.find(
                    (storeType) => storeType.value === RESTAURANT_STORE_TYPE_ID
                  ),
                  message: "O campo é obrigatório.",
                },
              }}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  label="Categorias"
                  isMandatory={cuisines.find(
                    (storeType) => storeType.value === "1"
                  )}
                  isMulti
                  loadOptions={fetchCategories}
                  loadingMessage={() => "Carregando..."}
                  noOptionsMessage={() => "Categoria não encontrada"}
                  placeholder={() => null}
                  error={errors.groups}
                  isOptionDisabled={(option) =>
                    option.isDisabled || categories?.length >= 2
                  }
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-3">
            <Controller
              name="online_tipo"
              control={control}
              rules={{ required: "O campo é obrigatório." }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Forma de abertura"
                  isMandatory
                  error={errors.online_tipo}
                  placeholder={() => null}
                  options={openingWayOptions}
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-2">
            <Input
              label="Estimativa mín. de entrega"
              isMandatory
              type="number"
              min={0}
              error={errors.entrega_minimo}
              {...register("entrega_minimo", {
                required: "O campo é obrigatório.",
              })}
              rightSideElement={<span class="w-40">minutos</span>}
            />
          </div>

          <div className="form-group col-md-2">
            <Input
              label="Estimativa máx. de entrega"
              isMandatory
              type="number"
              min={0}
              error={errors.entrega_maximo}
              {...register("entrega_maximo", {
                required: "O campo é obrigatório.",
              })}
              rightSideElement={<span class="w-40">minutos</span>}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <Controller
              name="id_representante"
              control={control}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  label="Representante"
                  defaultValue={getDefaultAgent()}
                  loadOptions={fetchAgents}
                  isDisabled={localStorage.getItem("@ADM-Aiboo/nivel") !== "A"}
                  isClearable
                  loadingMessage={() => "Carregando..."}
                  noOptionsMessage={() => "Representante não encontrado"}
                  placeholder={() => null}
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-2">
            <Input
              label="Multiloja - ID da loja pai"
              type="text"
              {...register("id_estabelecimento_pai")}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-5">
            <label>Funcionalidades</label>

            <div
              className="border rounded p-8 d-flex flex-column"
              style={{ gap: 14 }}
            >
              <div>
                <div className="checkbox-inline">
                  <label
                    className="checkbox checkbox-square checkbox-primary m-t-3"
                    style={{
                      textIndent: 10,
                    }}
                  >
                    <input type="checkbox" {...register("aceita_cupom")} />
                    Aceitar cupom
                    <span />
                  </label>
                </div>
              </div>

              {/* <div>
              <div className="checkbox-inline">
                <label
                  className="checkbox checkbox-square checkbox-primary m-t-3"
                  style={{
                    textIndent: 10,
                  }}
                >
                  <input type="checkbox" {...register("cobrar_multa")} />
                  Cobrar multa pedido recusado
                  <span />
                </label>
              </div>
            </div> */}

              <div>
                <div className="checkbox-inline">
                  <label
                    className="checkbox checkbox-square checkbox-primary m-t-3"
                    style={{
                      textIndent: 10,
                    }}
                  >
                    <input type="checkbox" {...register("upload_em_massa")} />
                    Upload em massa
                    <span />
                  </label>
                </div>
              </div>

              {/* <div>
              <div className="checkbox-inline">
                <label
                  className="checkbox checkbox-square checkbox-primary m-t-3"
                  style={{
                    textIndent: 10,
                  }}
                >
                  <input type="checkbox" {...register("auto_aceitar_pedido")} />
                  Aceitar pedido automaticamente
                  <span />
                </label>
              </div>
            </div> */}

              <div>
                <div className="checkbox-inline">
                  <label
                    className="checkbox checkbox-square checkbox-primary m-t-3"
                    style={{
                      textIndent: 10,
                    }}
                  >
                    <input
                      type="checkbox"
                      {...register("entrega_desativada")}
                    />
                    Desativar entrega
                    <span />
                  </label>
                </div>
              </div>

              <div>
                <div className="checkbox-inline">
                  <label
                    className="checkbox checkbox-square checkbox-primary m-t-3"
                    style={{
                      textIndent: 10,
                    }}
                  >
                    <input
                      type="checkbox"
                      {...register("retirada_desativada")}
                    />
                    Desativar retirada
                    <span />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <label>Exibição</label>

            <div
              className="border rounded p-8 d-flex flex-column gap-2"
              style={{ gap: 14 }}
            >
              <div className="form-group col-md-6 p-0 mb-2">
                <Input
                  label="Tag personalizada"
                  placeholder="Texto promocional"
                  type="text"
                  {...register("frase_promocional")}
                />
              </div>

              <div>
                <div className="checkbox-inline">
                  <label
                    className="checkbox checkbox-square checkbox-primary m-t-3"
                    style={{
                      textIndent: 10,
                    }}
                  >
                    <input type="checkbox" {...register("soon")} />
                    Tag "Em breve"
                    <span />
                  </label>
                </div>
              </div>

              <div>
                <div className="checkbox-inline">
                  <label
                    className="checkbox checkbox-square checkbox-primary m-t-3"
                    style={{
                      textIndent: 10,
                    }}
                  >
                    <input type="checkbox" {...register("exclusivo_aiboo")} />
                    Tag "Exclusivo"
                    <span />
                  </label>
                </div>
              </div>

              <div className="d-flex align-items-center" style={{ gap: 8 }}>
                <div className="checkbox-inline">
                  <label
                    className="checkbox checkbox-square checkbox-primary m-t-3"
                    style={{
                      textIndent: 10,
                    }}
                  >
                    <input type="checkbox" {...register("is_swimlane_view")} />
                    Apresentar itens em raias
                    <span />
                  </label>
                </div>

                <ReactTooltip />
                <span
                  className="d-flex"
                  data-tip="Os itens da loja serão apresentados em listas horizontais separados por categorias. Recomendado para grandes quantidades de itens."
                >
                  <i class="far fa-question-circle"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-6 mt-8">
          <div className="col-md-12">
            <h3>Configurações de pagamento</h3>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <div className="checkbox-inline">
              <label
                className="checkbox checkbox-square checkbox-primary m-t-3"
                style={{
                  textIndent: 10,
                }}
              >
                <input
                  type="checkbox"
                  {...register("aceita_pgto_presencial")}
                />
                Pagamento presencial
                <span />
              </label>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="form-group col-md-6">
            <Input
              label="Formas de Pagamento"
              type="text"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              rightSideElement={<i class="fas fa-search" />}
            />
          </div>

          <div className="form-group col-md-12">
            <div
              className="row form-group mb-10 text-left border rounded p-10 m-0 overflow-auto"
              style={{ height: 400 }}
            >
              {search.length > 0
                ? filteredPaymentMethodsList.map(renderPaymentMethod)
                : paymentMethods.map(renderPaymentMethod)}
            </div>
          </div>
        </div>

        <div className="row mb-6">
          <div className="col-md-12">
            <h3>Configurações financeiras</h3>
            <hr />
          </div>
        </div>

        <div className="row">
          <div data-tip={blockedFieldMessage} className="form-group col-md-2">
            <Input
              label="Taxa Aiboo"
              isMandatory
              readOnly={!!contractId}
              type="number"
              error={errors.comissao}
              min={0}
              {...register("comissao", {
                required: "O campo é obrigatório.",
                min: {
                  value: 0,
                  message: "A taxa mínima é 1%.",
                },
                max: {
                  value: 99,
                  message: "A taxa máxima é 99%",
                },
              })}
              rightSideElement={<span class="w-15">%</span>}
            />
          </div>

          <div className="form-group col-md-2">
            <Input
              label="Mensalidade"
              placeholder="R$"
              {...register("mensalidade", {
                onChange: (event) => {
                  const { value } = event.target;
                  event.target.value = stringUtil.maskToRealCurrency(value);
                },
              })}
            />
          </div>

          <div className="form-group col-md-2">
            <Input
              label="Fatura máxima"
              placeholder="R$"
              {...register("mensalidade_maxima", {
                onChange: (event) => {
                  const { value } = event.target;
                  event.target.value = stringUtil.maskToRealCurrency(value);
                },
              })}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-3">
            <Controller
              name="forma_calculo"
              control={control}
              rules={{ required: "O campo é obrigatório." }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Base de cálculo de comissão"
                  isMandatory
                  error={errors.forma_calculo}
                  placeholder={() => null}
                  options={calculationWayOptions}
                />
              )}
            />
          </div>

          <div
            className="form-group col-md-3 hidden"
            style={{ display: "none" }}
          >
            <Controller
              name="pagamento_status"
              control={control}
              // rules={{ required: "O campo é obrigatório." }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Status do pagamento"
                  isMandatory
                  error={errors.pagamento_status}
                  placeholder={() => null}
                  options={paymentStatusOptions}
                />
              )}
            />
          </div>
        </div>
      </form>
      <SearchLocationModal
        isOpen={isModalOpened}
        addressLabel={addressLabel}
        setIsModalOpened={setIsModalOpened}
        location={location}
        setLocation={setLocation}
        updateLocation={updateLocation}
      />
    </div>
  );
};

export const DataTab = memo(DataTabComponent);
