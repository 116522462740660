import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import LoadingOverlay from "react-loading-overlay";

import { merchantService } from "services";
import { formatMerchantLabel } from "./helpers";

import {
  useCreateFinancialAdjustment,
  useListFinancialPeriods,
} from "useCases";

import { stringUtil } from "utils";

import { PageLayout } from "components";
import { Form } from "./components";

export const CreateFinancialAdjustment = () => {
  const [isLoading, setIsLoading] = useState(true);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      description: "",
      type: "remove",
    },
  });
  const { merchantId } = useParams();
  const history = useHistory();

  const { addToast } = useToasts();

  const { periods } = useListFinancialPeriods({});
  const { createFinancialAdjustment } = useCreateFinancialAdjustment({
    onSuccess: () => {
      addToast("Ajuste criado com sucesso.", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 4000,
      });

      history.push("/fiscal/ajustes-financeiros");
    },
    onError: () =>
      addToast(
        "Ocorreu um erro ao salvar o ajuste, por favor, tente novamente mais tarde.",
        {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 4000,
        }
      ),
  });

  const onSubmit = ({ merchant, description, period, value, type }) => {
    createFinancialAdjustment({
      merchant_id: Number(merchant.value),
      financial_period_id: period.value.id,
      value: Number(stringUtil.removeRealCurrencyMask(value) * 100),
      description,
      type,
    });
  };

  const fetchDefaultMerchant = useCallback(async () => {
    try {
      if (!merchantId) {
        return;
      }

      const response = await merchantService.listByIdOrName({
        param: merchantId,
      });

      const [merchant] = response.estabelecimentos;

      setValue("merchant", {
        label: formatMerchantLabel(merchant),
        value: merchant.id_estabelecimento,
      });
    } catch (error) {
      addToast("Estabelecimento não encontrado.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 4000,
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast, merchantId, setValue]);

  useEffect(() => {
    fetchDefaultMerchant();
  }, [fetchDefaultMerchant]);

  return (
    <PageLayout
      headerTitle="Ajuste financeiro"
      headerSubtitle="Crie um ajuste financeiro para um estabelecimento"
      onSubmit={handleSubmit(onSubmit)}
    >
      <LoadingOverlay active={isLoading} spinner text="Carregando...">
        <Form
          register={register}
          watch={watch}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          setValue={setValue}
          control={control}
          errors={errors}
          financialPeriods={periods}
        />
      </LoadingOverlay>
    </PageLayout>
  );
};
